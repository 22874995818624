import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';
import { SearchService } from '../../EastclinicVueApi';
import type { SearchResultInterface } from '../../EastclinicVueApi';

export default class SeoState {
  public _searchSeoResults:Ref<SearchResultInterface[]> = ref([]);
  public searchSeoString = ref<string>('');
  public initSearchResults = ref<SearchResultInterface[]>([]);
  protected initSearchString:string = '';

  constructor() {
    // init search data from url
    const pageInfoStore = usePageInfoStore();
    this.getSearch(this.searchSeoString.value);
    const { pageInfo } = storeToRefs(pageInfoStore);
    const pageType = pageInfo.value?.type;

    this.searchSeoString.value = (pageInfo.value?.menutitle && ((pageType === 'disease' || pageType === 'service' || pageType === 'specialist') && pageInfo.value.id !== 6)) ? pageInfo.value?.menutitle : '';
    this.initSearchString = this.searchSeoString.value;

    watch(pageInfo, () => {
      const pageType = pageInfo.value?.type;
      this.searchSeoString.value = (pageInfo.value?.menutitle && ((pageType === 'disease' || pageType === 'service' || pageType === 'specialist') && pageInfo.value.id !== 6)) ? pageInfo.value?.menutitle : '';
      this.initSearchString = this.searchSeoString.value;
    });
  }

  // public setSearchSeoString(search:string) {
  //   // todo clear symbols
  //   search = search.replace(/[^0-9a-zA-ZА-ЯЁёа-я- ]+/ug, '');
  //   this.searchSeoString.value = search;
  // }

  public async getSearch(searchString:string) {
    searchString = searchString.replace(/[^0-9a-zA-ZА-ЯЁёа-я- ]+/ug, '');
    const data = await SearchService.searchFetch(searchString);
    this._searchSeoResults.value = (data && data.length) ? data : [];
    if (this.initSearchResults.value.length === 0) {
      this.initSearchResults.value = this._searchSeoResults.value;
    }
  }

  public searchSeoResults = computed(() => this._searchSeoResults.value);
  public resetSearch() {
    this.searchSeoString.value = this.initSearchString;
    this._searchSeoResults.value = this.initSearchResults.value;
  }

  public goSeoUrl(seoItem?:SearchResultInterface|string) {
    let path = '/vrachi/';
    const { currentClinic } = storeToRefs(useClinicsStore());
    if (typeof seoItem === 'string') {
      path = seoItem;
      path = (path.slice(-1) === '/' && path !== '/vrachi/') ? path.slice(0, -1) : path;
      if (currentClinic.value?.url) {
        path = path + '/' + currentClinic.value.url;
      }
    } else {
      path = (seoItem?.url) ? '/' + seoItem.url : '/vrachi/';
      // clear last slash for correct work
      path = (path.slice(-1) === '/' && path !== '/vrachi/') ? path.slice(0, -1) : path;

      if (currentClinic.value?.url && seoItem?.type !== 'doc') {
        path = path + '/' + currentClinic.value.url;
      }
    }
    if (path) {
      this.resetSearch();
      navigateTo(path);
    }
  }
}
